import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect } from "react";
import { Alert, Button, Figure, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import Logo from '../assets/jtts_logo.png';
import '../style/sign-in.css';

export default function Login() {
    const [authenticated, setAuthenticated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [ruasSelected, setRuasSelected] = useState('');
    const [selectedRuas, setSelectedRuas] = useState(null);

    const navigate = useNavigate();

    const formSchema = Yup.object().shape({
        email: Yup.string().required('Tidak boleh kosong'),
        password: Yup.string().required('Tidak boleh kosong').min(3, 'Password harus lebih dari 3 karakter'),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(formSchema) });

    // Daftar users dan ruas
    const users = [
        { username: "Atp2024", password: "External2024", value: '1' },
        { username: "Jorrs2024", password: "External2024", value: '2' },
        { username: "Palindra2024", password: "External2024", value: '3' },
        { username: "Mebi2024", password: "External2024", value: '4' },
        { username: "Bakter2024", password: "External2024", value: '5' },
        { username: "Terpeka2024", password: "External2024", value: '6' },
        { username: "Permai2024", password: "External2024", value: '7' },
        { username: "Sibanceh2024", password: "External2024", value: '8' },
        { username: "Binsa2024", password: "External2024", value: '9' },
        { username: "Pekbang2024", password: "External2024", value: '10' },
        { username: "Bengtab2024", password: "External2024", value: '11' },
        { username: "Indraprabu2024", password: "External2024", value: '12' },
        { username: "Inkis2024", password: "External2024", value: '13' },
        { username: "Betejam2024", password: "External2024", value: '14' },
        { username: "Pacin2024", password: "External2024", value: '15' },
    ];

    const namaRuas = [
        { name: 'Ruas ATP', alias: 'ATP', value: '1' },
        { name: 'Ruas JORRS', alias: 'JORRS', value: '2' },
        { name: 'Ruas PALINDRA', alias: 'PALINDRA', value: '3' },
        { name: 'Ruas MEBI', alias: 'MEBI', value: '4' },
        { name: 'Ruas BAKTER', alias: 'BAKTER', value: '5' },
        { name: 'Ruas TERPEKA', alias: 'TERPEKA', value: '6' },
        { name: 'Ruas PERMAI', alias: 'PERMAI', value: '7' },
        { name: 'Ruas SIBANCEH', alias: 'SIBANCEH', value: '8' },
        { name: 'Ruas BINSA', alias: 'BINSA', value: '9' },
        { name: 'Ruas PEKBANG', alias: 'PEKBANG', value: '10' },
        { name: 'Ruas BENGTAB', alias: 'BENGTAB', value: '11' },
        { name: 'Ruas INDRAPRABU', alias: 'INDRAPRABU', value: '12' },
        { name: 'Ruas INKIS', alias: 'INKIS', value: '13' },
        { name: 'Ruas BETEJAM', alias: 'BETEJAM', value: '14' },
        { name: 'Ruas PACIN', alias: 'PACIN', value: '15' },
    ];

    // Function to handle dropdown change
    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedRuas(selectedValue);
    };

    const onSubmit = (data) => {
        const { email, password } = data;

        const account = users.find(user => user.username === email && user.password === password);

        if (account && selectedRuas === account.value) {
            setAuthenticated(true);
            localStorage.setItem("authenticated", true);
            localStorage.setItem("ruasSelected", selectedRuas);

            navigate(selectedRuas === '99' ? '/' : '/cctv/grid');
        } else {
            setAuthenticated(false);
            setShowAlert(true);
            localStorage.setItem("authenticated", false);
            localStorage.setItem("ruasSelected", 0);
        }
    };

    // useEffect to check authentication status
    useEffect(() => {
        const isAuthenticated = localStorage.getItem("authenticated") === 'true';
        if (isAuthenticated) {
            navigate('/cctv/grid');
        }
    }, [authenticated, navigate]);

    return (
        <main className='form-signin m-auto text-center'>
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
                {errors.email && <Alert variant="warning" className='w-100'>{errors.email.message}</Alert>}
                {errors.password && <Alert variant="warning" className='w-100'>{errors.password.message}</Alert>}
                {showAlert && <Alert variant="danger" className='w-100'>Anda tidak mempunyai akses untuk Ruas Tol tersebut.</Alert>}

                <Form onSubmit={handleSubmit(onSubmit)} className='w-100'>
                    <Figure>
                        <Figure.Image
                            width={100}
                            height={100}
                            alt='logo-hk'
                            src={Logo}
                        />
                    </Figure>
                    <h1 className='h3 mb-3'>Please sign in</h1>

                    <div className="form-floating">
                        <Form.Control type="text" name="email" className="form-control" placeholder="Email" {...register('email')} />
                        <label>Username</label>
                    </div>

                    <div className="form-floating">
                        <Form.Control type="password" name="password" className="form-control" placeholder="Password" {...register('password')} />
                        <label>Password</label>
                    </div>

                    <div className="">
                        <Form.Control as="select" onChange={handleChange} defaultValue="">
                            <option value="" disabled>Pilih Ruas Tol</option>
                            {namaRuas.map((ruas, index) => (
                                <option key={index} value={ruas.value}>{ruas.name}</option>
                            ))}
                        </Form.Control>
                    </div>

                    <Button size="md" className="w-100 mt-3" as="input" type="submit" value="Login" />
                    <p className="mt-5 mb-3 text-body-secondary">&copy; 2017–2024</p>
                </Form>
            </div>
        </main>
    );
}
